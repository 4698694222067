<template>
  <section class="info-box info-box--bottom-content">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="info-box__item">
                        <i class="fas fa-question-circle"></i>
                        <div class="info-box__item-right">
                            <h3 class="title-secondary bold-weight mb-2">Kundenservice</h3>
                            Sie haben Fragen rund um unsere Produkte oder den Bestellprozess?
                            Melden Sie sich am besten per Telefon unter folgender
                            <br>Nummer: <a href="tel:+493031007700">+493031007700</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="info-box__item">
                        <i class="fas fa-credit-card"></i>
                        <div class="info-box__item-right">
                            <h3 class="title-secondary bold-weight mb-2">Zahlungsmittel</h3>
                            Wir akzeptieren folgende Zahlungsarten: <br><a href="https://loewe-schiebetore.de/zahlungsbedingungen/" target="_blank">Überweisung, PayPal oder Barzahlung unseren Filialen</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="info-box__item">
                        <i class="fas fa-truck"></i>
                        <div class="info-box__item-right">
                            <h3 class="title-secondary bold-weight mb-2">Liefer- und Montageservice</h3>
                            Wir arbeiten mit eigener Spedition und geschultem Montagepersonal. Ihre
                            <a href="https://loewe-schiebetore.de/lieferbedingungen/" target="_blank">Zaunanlage montieren wir professionell und fachgerecht.</a>
                        </div>
                    </div>
                </div>
            </div>
  </section>
</template>
<script>
export default {
  setup () {}
}
</script>
